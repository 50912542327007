import "./Insights.scss"
import {MDBCol, MDBContainer, MDBRow} from "mdb-react-ui-kit";


export const Insights = (props) => {
     return(
        <div className={"insight-component"}>
            <div className={"content-section"}>
                <p className={"title"}>Results with RippleAR</p>
                <p className={"description"}>
                    Ripple AR has achieved exponential growth over a short span of time. Here is a peak at our numbers:​
                </p>
            </div>
            <div className={"card-content-section"}>
                <MDBContainer className={"p-0"}>
                    <MDBRow className='mb-3'>
                        <MDBCol sm='3' className='col-example text-center'>
                            <p className={"title"}>3.6 Billion</p>
                            <p className={"description"}>smartphone devices supported by Ripple AR platform</p>
                        </MDBCol>
                        <MDBCol sm='3' className='col-example text-center'>
                            <p className={"title"}>17%</p>
                            <p className={"description"}>Increase in CTR</p>
                        </MDBCol>
                        <MDBCol sm='3' className='col-example text-center'>
                            <p className={"title"}>5x</p>
                            <p className={"description"}>Increase in applicants</p>
                        </MDBCol>
                        <MDBCol sm='3' className='col-example text-center'>
                            <p className={"title"}>2min</p>
                            <p className={"description"}>dwell time</p>
                        </MDBCol>
                    </MDBRow>
                </MDBContainer>
            </div>
        </div>
    );
}

