import "./Navbar.scss";
import {
    MDBBtn,
    MDBCollapse,
    MDBContainer, MDBInput,
    MDBNavbar,
    MDBNavbarBrand, MDBNavbarItem, MDBNavbarLink, MDBNavbarNav, MDBTextArea
} from 'mdb-react-ui-kit';
import {
    MDBModal,
    MDBModalDialog,
    MDBModalContent,
    MDBModalHeader,
    MDBModalTitle,
    MDBModalBody,
    MDBModalFooter,
} from 'mdb-react-ui-kit';
import Logo from "../../assets/logo.png";
import CancelIcon from "../../assets/cancel-icon.png";
import SuccessIcon from "../../assets/thankyou.gif";
import * as EmailValidator from 'email-validator';
import cogoToast from 'cogo-toast';
import Lottie from 'react-lottie-player'
import lottieJson from '../../assets/success1.json';

import {useState} from "react";



export const Navbar = (props) => {
    const [centredModal, setCentredModal] = useState(false);
    const [formSubmitted, setFormSubmitted] = useState(false);

    const [name, setName] = useState("");
    const [phoneNo, setPhoneNo] = useState("");
    const [emailId, setEmailId] = useState("");
    const [organizationName, setOrganizationName] = useState("");
    const [message, setMessage] = useState("");

    const toggleShow = () => {
        setCentredModal(!centredModal)
        setFormSubmitted(false);
    };
    const submitForm = () => {
        if(name === ""){
            cogoToast.error('Please fill name',{ position: 'top-center'});
        } else if(phoneNo === "") {
            cogoToast.error('Please fill phone number',{ position: 'top-center'});

        } else if(phoneNo.length < 10) {
            cogoToast.error('Please fill valid phone number',{ position: 'top-center'});

        } else if(EmailValidator.validate(emailId) === false) {
            cogoToast.error('Please fill valid email',{ position: 'top-center'});

        } else if(organizationName === "") {
            cogoToast.error('Please fill organization name',{ position: 'top-center'});

        } else if(message === "") {
            cogoToast.error('Please fill message',{ position: 'top-center'});

        } else {
            let data = {
                "name":name,
                "phone_number":phoneNo,
                "email":emailId,
                "organization_name":organizationName,
                "message":message
            }
            fetch('https://ripple-be.deepsense.dev/api/v1/contact_us', {
                method: 'post',
                headers: {'Content-Type':'application/json'},
                body: JSON.stringify(data)
            }).then(response => {
                return response.json();
            }).then(json => {
                if(json.message){
                    setName("");
                    setPhoneNo("");
                    setEmailId("");
                    setOrganizationName("");
                    setMessage("");
                    // cogoToast.success(json.message,{ position: 'top-center'});
                    setFormSubmitted(true);

                }
            });
        }

        // setFormSubmitted(true);
    }

    return(
        <>
            <div className={"navbar-component"}>
                <div id="scrollspy1" className="sticky-top">
                    <MDBNavbar expand='lg' >
                        <MDBContainer fluid className={"p-0"}>
                            <MDBNavbarBrand href='#'>
                                <img
                                    src={Logo}
                                    height={'40'}
                                    alt=''
                                    loading='lazy'
                                />
                            </MDBNavbarBrand>
                            <MDBNavbarNav right fullWidth={false} className='mr-auto hide-xs-navbar-nav'>
                                    <MDBNavbarItem>
                                        <MDBNavbarLink active aria-current='page' href='#industry-verticals'>
                                            Industry Verticals
                                        </MDBNavbarLink>
                                    </MDBNavbarItem>
                                    {/*<MDBNavbarItem>*/}
                                    {/*    <MDBNavbarLink active aria-current='page' href='#what-we-done'>*/}
                                    {/*        What we done ?*/}
                                    {/*    </MDBNavbarLink>*/}
                                    {/*</MDBNavbarItem>*/}
                                    <MDBNavbarItem>
                                        <MDBNavbarLink active aria-current='page' href='#insights'>
                                            Insights
                                        </MDBNavbarLink>
                                    </MDBNavbarItem>
                                    <MDBNavbarItem>
                                        <MDBNavbarLink active aria-current='page'>
                                            <div className={"contact-us-btn-section"}>
                                                <MDBBtn tag='div' color='none' className={"contact-us-btn"}  type='button'
                                                        onClick={toggleShow}
                                                >
                                                    Contact us
                                                </MDBBtn>
                                            </div>
                                        </MDBNavbarLink>
                                    </MDBNavbarItem>
                                </MDBNavbarNav>
                            <MDBNavbarNav right fullWidth={false} className='mr-auto show-xs-navbar-nav'>
                                <MDBNavbarItem>
                                    <MDBNavbarLink active aria-current='page' onClick={toggleShow}>
                                        Contact us
                                    </MDBNavbarLink>
                                </MDBNavbarItem>
                            </MDBNavbarNav>
                        </MDBContainer>
                    </MDBNavbar>
                </div>
            </div>
            <MDBModal tabIndex='-1' show={centredModal} setShow={setCentredModal} id={"contact-us-modal"}>
                <MDBModalDialog centered size='fullscreen-xl-down'>
                    <MDBModalContent>
                        <MDBModalHeader>
                            <MDBModalTitle style={{visibility:!formSubmitted ?'visible':'hidden'}}>Masterclass Registration</MDBModalTitle>
                            <MDBBtn className='close-btn' color='none' onClick={toggleShow}>
                                <img src={CancelIcon} alt={"CancelIcon"} width={"24px"}/>
                                <p className={"mobile-title mb-0"}>Fill the details</p>
                            </MDBBtn>
                        </MDBModalHeader>
                        <MDBModalBody>
                            {
                                !formSubmitted ?
                                    <div>
                                        <div className={"form-content-section"}>
                                            <p className={"title mb-0"}>Improve Your Product Experience with</p>
                                            <p className={"sub-title"}>Ripple AR</p>
                                        </div>
                                        <MDBInput className='input-feild-section' type='text' id='form1Example1' label='Name' value={name} onChange={(e)=>setName(e.target.value)} />
                                        <MDBInput className='input-feild-section' type='number' id='form1Example2' label='Phone Number' value={phoneNo}  onChange={(event) => {
                                            if(event.target.value.length===11) return false;   //limits to 10 digit entry
                                            setPhoneNo(event?.target.value);       //saving input to state
                                        }
                                        }/>
                                        <MDBInput className='input-feild-section' type='email' id='form1Example1' label='Email' value={emailId} onChange={(e)=>setEmailId(e.target.value)}  />
                                        <MDBInput className='input-feild-section' type='text' id='form1Example2' label='Organization Name' value={organizationName} onChange={(e)=>setOrganizationName(e.target.value)} />
                                        <MDBTextArea className={'input-feild-section'} label='Message' id='textAreaExample' rows={8} cols={8} value={message} onChange={(e)=>setMessage(e.target.value)} />
                                        <MDBBtn className={"submit-btn d-flex justify-content-center align-items-center"}
                                                onClick={submitForm}
                                        >
                                            Submit
                                        </MDBBtn>

                                    </div>:
                                    <div className={"thankyou-section text-center"}>
                                        {/*<img src={SuccessIcon} alt={"SuccessIcon"} className={"img-fluid"}/>*/}
                                        <Lottie
                                            loop
                                            animationData={lottieJson}
                                            play
                                            // style={{ width: 150, height: 150 }}
                                        />
                                        <p className={"content"}>Thanks!
                                            For your response</p>
                                    </div>
                            }

                        </MDBModalBody>
                    </MDBModalContent>
                </MDBModalDialog>
            </MDBModal>
        </>
    )
}
