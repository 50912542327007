import "./IndustryVerticals.scss"
import { MDBContainer, MDBRow, MDBCol } from 'mdb-react-ui-kit';

import IndustrialVerticalImg1 from "../../assets/industrial-vertical-img1.png";
import IndustrialVerticalImg2 from "../../assets/industrial-vertical-img2.png";
import IndustrialVerticalImg3 from "../../assets/industrial-vertical-img3.png";
import IndustrialVerticalImg4 from "../../assets/industrial-vertical-img4.png";
import IndustrialVerticalImg5 from "../../assets/industrial-vertical-img5.png";
import IndustrialVerticalImg6 from "../../assets/industrial-vertical-img6.png";
import IndustrialVerticalImg7 from "../../assets/industrial-vertical-img7.png";
import IndustrialVerticalImg8 from "../../assets/industrial-vertical-img8.png";

import IndustrialVerticalBorder1 from "../../assets/industrial-vertical-img-border1.png";
import IndustrialVerticalBorder2 from "../../assets/industrial-vertical-img-border2.png";
import IndustrialVerticalBorder3 from "../../assets/industrial-vertical-img-border3.png";
import IndustrialVerticalBorder4 from "../../assets/industrial-vertical-img-border4.png";
import IndustrialVerticalBorder5 from "../../assets/industrial-vertical-img-border5.png";
import IndustrialVerticalBorder6 from "../../assets/industrial-vertical-img-border6.png";
import IndustrialVerticalBorder7 from "../../assets/industrial-vertical-img-border7.png";
import IndustrialVerticalBorder8 from "../../assets/industrial-vertical-img-border8.png";

export const IndustryVerticals = (props) => {
    const data1 = [
        {
            'img':IndustrialVerticalImg1,
            'title':'Food & Beverages',
            'outter_bg':IndustrialVerticalBorder1,
        },
        {
            'img':IndustrialVerticalImg2,
            'title':'Electronics',
            'outter_bg':IndustrialVerticalBorder2,
        },
        {
            'img':IndustrialVerticalImg3,
            'title':'Automobiles',
            'outter_bg':IndustrialVerticalBorder3,
        },
        {
            'img':IndustrialVerticalImg4,
            'title':'Entertainment',
            'outter_bg':IndustrialVerticalBorder4,
        }

    ];
    const data2 = [
        {
            'img':IndustrialVerticalImg5,
            'title':'Beauty & Wellness',
            'outter_bg':IndustrialVerticalBorder5,
        },
        {
            'img':IndustrialVerticalImg6,
            'title':'Fashion',
            'outter_bg':IndustrialVerticalBorder6,
        },
        {
            'img':IndustrialVerticalImg7,
            'title':'Education',
            'outter_bg':IndustrialVerticalBorder7,
        },
        {
            'img':IndustrialVerticalImg8,
            'title':'Kitchen Appliances',
            'outter_bg':IndustrialVerticalBorder8,
        }
    ];

    return(
        <div className={"industry-vertical-component"}>
            <div className={"content-section"}>
                <p className={"title"}>Industry Verticals</p>
                <p className={"description"}>
                    Ripple AR AI Engine powers World Effects, Face Effects, and Image Target experiences that work in the browser across all devices which can be used across a wide range of industries. ​
                </p>
            </div>
            <MDBContainer className={"industry-vertical-container"}>
                <MDBRow>
                    {
                        data1.map((item,index)=>{
                            return(
                                <MDBCol size='6' sm={'3'} className='col-example' key={index}>
                                    <IndustryVerticalsCard cardData={item}/>
                                </MDBCol>
                            )
                        })
                    }
                </MDBRow>
                <div className={"spacer"}/>
                <MDBRow>
                    {
                        data2.map((item,index)=>{
                            return(
                                <MDBCol size='6' sm={'3'} className='col-example' key={index}>
                                    <IndustryVerticalsCard cardData={item}/>
                                </MDBCol>
                            )
                        })
                    }
                </MDBRow>
            </MDBContainer>
        </div>
    );
}

const IndustryVerticalsCard = (props) => {
    const {cardData} = props;
    return(
        <div className={"industry-vertical-card-component"}
             style={{backgroundImage:`url(${cardData.outter_bg})`}}
        >
            <div className={"inner-content"}>
                <img src={cardData.img} alt={"IndustrialVerticalImg"} className={"img-fluid"}/>
                <p className={"title"}>{cardData.title}</p>
            </div>
        </div>
    )
}
