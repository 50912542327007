import './App.scss';
import {Navbar} from "./components/Navbar/Navbar";
import {HeroSection} from "./components/HeroSection/HeroSection";
import {IndustryVerticals} from "./components/IndustryVerticals/IndustryVerticals";
import {WhatWeDone} from "./components/WhatWeDone/WhatWeDone";
import {Insights} from "./components/Insights/Insights";
import {Footer} from "./components/Footer/Footer";


function App() {
    return (
        <div className="app-component">
            <Navbar/>
            <HeroSection/>
            <div
                data-mdb-spy="scroll"
                data-mdb-target="#scrollspy1"
                data-mdb-offset="0"
                className="scrollspy-example"
            >
             <section id={"industry-verticals"}>
                 <IndustryVerticals/>
             </section>
             {/*<section id={"what-we-done"}>*/}
             {/*   <WhatWeDone/>*/}
             {/*</section>*/}
             <section id={"insights"}>
                <Insights/>
             </section>
            </div>
            <Footer/>
        </div>
    );
}

export default App;
