import React from "react";
import "./HeroSection.scss";
import { MDBBtn } from 'mdb-react-ui-kit';

import PhoneImg from "../../assets/phone-img.png";

import DesktopVideo from "../../assets/Visitingcardwebdimension.mp4";
import MobileVideo from "../../assets/Visitingcardmobiledimension.mp4";
import {useEffect, useRef} from "react";

import Media from 'react-media';

export const HeroSection = (props) => {
    const videoRef = useRef();
    const circlePlayButtonRef = useRef();

    useEffect(()=>{
        circlePlayButtonRef.current.addEventListener("click", togglePlay);
        videoRef.current.addEventListener("playing", function () {
            circlePlayButtonRef.current.style.opacity = 0;
        });
        videoRef.current.addEventListener("pause", function () {
            circlePlayButtonRef.current.style.opacity = 1;
        });

    },[])

    const togglePlay = () => {
        if (videoRef.current.paused || videoRef.current.ended) {
            videoRef.current.play();
        } else {
            videoRef.current.pause();
        }
    }

    const tryNow = () => {
        window.open("https://ripplear.world/businesscard/rithesh","_blank")
    }

    return(
        <div className={"hero-section-component"}>
            <div className={"content-section"}>
                <p className={"content1"}>Improve Your Product <br/> Experience with
                </p>
                <p className={"content2"}>Ripple AR</p>
                <MDBBtn className={"try-now-btn d-flex justify-content-center align-items-center"}
                        onClick={tryNow}
                >
                    Try Now
                </MDBBtn>
            </div>
            <div className={"image-section"}>
                <div className={"large-device-video-section"}>
                    <div className="video-wrapper">
                        <div className="video-container" id="video-container">
                            <video width={"100%"} height={"100%"}  playsInline={true} id="video" ref={videoRef} preload="metadata"
                                  >
                                <Media queries={{
                                    small: "(max-width: 768px)"
                                }}>
                                    {matches => (
                                        <React.Fragment>
                                            {matches.small?
                                                <source src={MobileVideo}
                                                        type="video/mp4"/>
                                                :
                                                <source src={DesktopVideo}
                                                        type="video/mp4"/>
                                            }
                                        </React.Fragment>
                                    )}
                                </Media>

                            </video>
                            {/*<video  width={"100%"} height={"100%"} muted={true}  playsInline={true} autoPlay loop  src={DesktopVideo} ref={videoRef}>*/}
                            {/*</video>*/}
                            <div className="play-button-wrapper">
                                <div title="Play video" className="play-gif" id="circle-play-b" ref={circlePlayButtonRef}>
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 80 80">
                                        <path d="M40 0a40 40 0 1040 40A40 40 0 0040 0zM26 61.56V18.44L64 40z"/>
                                    </svg>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                {/*<div className={"small-device-video-section"}>*/}
                {/*    <video  width={"100%"} height={"100%"}  playsInline={true} autoPlay loop  src={MobileVideo}>*/}
                {/*    </video>*/}
                {/*</div>*/}
                {/*<img src={PhoneImg} alt={"phone"} className={"img-fluid phone-img"} />*/}
            </div>
        </div>
    );
}
