import "./Footer.scss";
import {
    MDBFooter,
    MDBContainer,
    MDBCol,
    MDBRow, MDBIcon, MDBNavbarLink
} from 'mdb-react-ui-kit';

import FooterLogo from "../../assets/logo.png";

export const Footer = (props) => {
    return(
        <div className={"footer-component"}>
            <MDBFooter  className='hide-xs-device text-md-left'>
                <MDBContainer className={"footer-container"} fluid={true}>
                    <MDBRow>
                        <MDBCol md='4' className={"text-center"}>
                            <img src={FooterLogo} alt={'FooterLogo'} className={"img-fluid footer-item-img"} width={"228px"}/>
                            <p className={"footer-description mb-0"}>
                                Ripple AR, conceived in the year 2022, by the people who has always been fascinated on growth of technology. We believe that augmented reality AR is the future, and everyone is going to be a user.                             </p>
                        </MDBCol>
                        <MDBCol md='4'>
                            <div className={"site-links-section"}>
                                <p className='footer-item-title'>Site Links</p>
                                <ul className='list-unstyled footer-item-list'>
                                    {/*<li>*/}
                                    {/*    <a href='#!' className='footer-item-list-content'>*/}
                                    {/*        Try Now*/}
                                    {/*    </a>*/}
                                    {/*</li>*/}
                                    {/*<li>*/}
                                    {/*    <a href='#!' className='footer-item-list-content'>*/}
                                    {/*        Contact us*/}
                                    {/*    </a>*/}
                                    {/*</li>*/}
                                    <li>
                                        <a  href='#industry-verticals' className='footer-item-list-content'>
                                            Industry Verticals
                                        </a>
                                    </li>
                                    {/*<li>*/}
                                    {/*    <a href='#!' className='footer-item-list-content'>*/}
                                    {/*        Explore RippleAR*/}
                                    {/*    </a>*/}
                                    {/*</li>*/}
                                    <li>
                                        <a href='#insights' className='footer-item-list-content'>
                                            Results
                                        </a>
                                    </li>

                                </ul>
                            </div>

                        </MDBCol>

                        <MDBCol md='4' >
                            <p className='footer-item-title'>Get to Know Us</p>

                            <ul className='list-unstyled footer-item-list'>
                                <li>
                                    <a href='#!' className='footer-item-list-content'>
                                        <MDBIcon far icon="envelope" style={{marginRight:"10px"}} />
                                        helloworld@ripplear.live
                                    </a>
                                </li>
                                {/*<li>*/}
                                {/*   <SocialIconsList/>*/}
                                {/*</li>*/}
                            </ul>
                        </MDBCol>
                    </MDBRow>
                </MDBContainer>

                <div className='text-center copy-right-section' style={{ backgroundColor: '#fff' }}>
                    Copyright {new Date().getFullYear()}.All rights reserved.
                </div>
            </MDBFooter>
            <div className={"mobile-footer-section show-xs-device text-center"}>
                <img src={FooterLogo} alt={'FooterLogo'} className={"img-fluid footer-item-img"} width={"228px"}/>
                {/*<div>*/}
                {/*    <a href={"#!"} className={"footer-item-tile"}>*/}
                {/*        Try Now*/}
                {/*    </a>*/}
                {/*</div>*/}
                {/*<div>*/}
                {/*    <a href={"#!"} className={"footer-item-tile"}>*/}
                {/*        Contact us*/}
                {/*    </a>*/}
                {/*</div>*/}
                {/*<div>*/}
                {/*    <a href={"#!"} className={"footer-item-tile"}>*/}
                {/*        Explore RippleAR*/}
                {/*    </a>*/}
                {/*</div>*/}
                <div>
                    <a href='#industry-verticals' className={"footer-item-tile"}>
                        Industry Verticals
                    </a>
                </div>
                <div>
                    <a href='#insights' className={"footer-item-tile"}>
                        Results with Ripple AR
                    </a>
                </div>
                <div>
                    <a href={"#!"} className={"footer-item-tile"}>
                        <MDBIcon far icon="envelope" style={{marginRight:"10px"}} />
                        helloworld@ripplear.live
                    </a>
                </div>
                {/*<div>*/}
                {/*    <SocialIconsList/>*/}
                {/*</div>*/}


            </div>
        </div>
    );
}

const SocialIconsList = (props) => {
    return(
        <section>
            <a
                className='btn  btn-floating btn-lg'
                href='#!'
                role='button'
            >
                <MDBIcon fab className='fa-instagram social-icons' />
            </a>
            <a
                className='btn  btn-floating btn-lg'
                href='#!'
                role='button'
            >
                <MDBIcon fab className='fa-twitter social-icons' />
            </a>
            <a
                className='btn  btn-floating btn-lg'
                href='#!'
                role='button'
            >
                <MDBIcon fab className='fab fa-facebook-f social-icons' />
            </a>
            <a
                className='btn  btn-floating btn-lg'
                href='#!'
                role='button'
            >
                <MDBIcon fab className='fa-linkedin social-icons' />
            </a>
            <a
                className='btn btn-floating btn-lg'
                href='#!'
                role='button'
            >
                <MDBIcon fab className='fa-youtube social-icons' />
            </a>
        </section>
    );
}
